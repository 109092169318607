<template lang="pug">
.TikTokVideos
  //- Header.absoluteHeader
  .ContainerHeader.pb-5
    .d-flex.align-items-center.justify-content-between
      img(
        src="@/assets/mobileFSFB/icn_backicn_back.png"
        alt="icn_backicn_back"
        @click="() => goToView({ view: 'Turn' })"
      ).icn_backicn_back.ml-3.backButton
      h3.fontTitleHeader.m-0 Turno asignado
      img(src="@/assets/mobileFSFB/icn_backicn_back.png" alt="icn_backicn_back").icn_backicn_back.opacity-0.mr-3
    //- .botton-actions.ml-4.pl-2
    //-   .d-flex.justify-content-start
    //-     button(@click="goBack" type="button").button-without-styles
    //-       svg(width='20' height='19' viewbox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg')
    //-         path(d='M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269' stroke='#0058A2' stroke-width='3.12307' stroke-linecap='round' stroke-linejoin='round')
  .TikTokVideosContainer
    video(
      v-for="(video, index) in videos"
      :key="index"
      :src="video"
      loop
      controls
    ).VideoColmedica
    .boxAttendee(
      @drop="handlerDrop"
      @dragover="handlerDragover"
    )
      h4.text-center.AssignmentTextAttendee Turno asignado:
      h3.text-center.font-weight-bold.ImportantAsignment {{ assignTurn }}
      //- h5.mt-1.text-center.mb-1.ActualAttendee Próximo turno:
      //- h5.text-middle.font-larger {{ actualTurn }}
      h5.mt-1.text-center.mb-1.ActualAttendee Espera:
      h5.text-middle.font-larger {{ waitingTime }}
    .containerDescTikTok(v-if="showSignal")
      img.containerDescTikTok-img(src="@/assets/mobileFSFB/handIconFSFB.svg")
    b-button(
      variant="bluecolmedica"
      size="sm"
      @click="() => goToView({ view: 'CancelTurn' })"
      type="button").w-100.py-2.mx-1.text-center.buttonTurnero2.mt-3
      span.font-md Cancelar turno
</template>

<script>
import interact from "interactjs";
import { mapActions } from "vuex";

export default {
  name: "TikTokVideos",

  data: () => ({
    showSignal: true,
    videos: [
      "https://cdn.videvo.net/videvo_files/video/free/2021-04/large_watermarked/210329_06B_Bali_1080p_013_preview.mp4",
      "https://static.videezy.com/system/resources/previews/000/033/826/original/pattaya-aerial-view30.mp4",
      "https://static.videezy.com/system/resources/previews/000/038/090/original/guitar_string4.mp4",
      "https://cdn.videvo.net/videvo_files/video/free/2017-12/large_watermarked/171124_B1_HD_001_preview.mp4"
    ]
  }),

  props: ["actualTurn", "assignTurn", "goBack", "waitingTime"],

  mounted() {
    const position = { x: 0, y: 0 };
    const boxAttendee = interact(".boxAttendee");

    boxAttendee.draggable({
      listeners: {
        move(event) {
          position.x += event.dx;
          position.y += event.dy;

          event.target.style.transform = `translate(${position.x}px, ${position.y}px)`;
        }
      }
    });

    const timer = setTimeout(() => {
      this.showSignal = false;

      clearTimeout(timer);
    }, 3500);
    const options = {};

    this.observer = new IntersectionObserver(this.handlerObserver, options);

    [...document.querySelectorAll(".VideoColmedica")].map($videoColmedica => {
      this.observer.observe($videoColmedica);
    });
  },

  methods: {
    ...mapActions({
      goToView: "mobileFSFB/goToView"
    }),
    handlerObserver(entries) {
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          entry.target.pause();
        } else {
          [...document.querySelectorAll(".VideoColmedica")].map(
            $videoColmedica => {
              $videoColmedica.pause();
            }
          );
          entry.target.play();
        }
      });
    },
    handlerDrop(event) {
      console.log("Fichero(s) arrastrados", event);
    },
    handlerDragover(ev) {
      console.log("File(s) in drop zone");

      // Prevent default behavior (Prevent file from being opened)
      ev.preventDefault();
    }
  }
};
</script>

<style lang="scss" scoped>
.TikTokVideosContainer {
  -ms-scroll-snap-type: y mandatory;
  scroll-snap-type: y mandatory;
  border-radius: 15px 15px 0 0;
  height: calc(100vh - 144px - 90px);
  overflow: auto;
}

.TikTokVideos {
  width: 100%;
  height: 100%;

  overflow: auto;
  background-color: #fafafb;

  .absoluteHeader {
    position: fixed;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
  }

  .VideoColmedica {
    width: 100%;
    height: 100%;
    scroll-snap-align: center;
    object-fit: cover;
  }

  .buttonTurnero2 {
    position: fixed;
    bottom: 36px;
    left: 50%;
    transform: translateX(-50%);

    font-size: 16px;
    line-height: 23px;
    text-align: center;
    text-decoration-line: underline;
    color: #009de0;
    background: none;
    border: none;
  }

  .boxAttendee {
    width: 100%;
    max-width: 150px;
    padding: 0.5rem;

    position: fixed;
    top: 96px;
    right: 32px;
    -ms-touch-action: none;
    touch-action: none;

    border-radius: 15px;
    background: rgba(244, 244, 244, 0.65);
    .ImportantAsignment {
      color: var(--color-radiant);
    }
    .font-larger {
      margin: auto;
      text-align: center;
      font-weight: bold;
      font-size: 14px;
    }
    .AssignmentTextAttendee,
    .ImportantAsignment {
      font-size: 16px;
    }
    .ActualAttendee {
      font-size: 14px;
    }
  }

  .containerDescTikTok {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;

    filter: drop-shadow(0px 28px 36px rgba(0, 0, 0, 1));

    img.containerDescTikTok-img {
      max-width: 142px;
      width: 100%;
    }

    .containerDescTikTok-text {
      color: #f4f4f4;
      text-align: center;
    }
  }
}

.backButton {
  padding-top: 21px;
}
</style>
